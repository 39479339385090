import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    userInfo: window.localStorage.getItem("user")
      ? JSON.parse(window.localStorage.getItem("user"))
      : {},
    token: window.localStorage.getItem("token") ?? "",
    hasLogin: false,
  }),
  actions: {
    saveUser(user) {
      this.userInfo = user;
      window.localStorage.setItem("user", JSON.stringify(user));
    },
    saveToken(token) {
      if (token.length > 0) {
        this.token = token;
        this.hasLogin = true;
        window.localStorage.setItem("token", token);
      } else {
        this.token = token;
        this.hasLogin = false;
        window.localStorage.removeItem("token");
      }
    },
  },
});
