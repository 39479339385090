<template>
  <button @click="toClearData" class="btn-login">clear</button>
  <div class="my has-nav">
    <div class="info">
      <img
        v-if="userInfo"
        :src="userInfo.avatar"
        class="headshot"
        mode="aspectFit"
      />
      <text v-if="userInfo">{{ userInfo.nickname }}</text>
      <button class="btn-login" v-if="!userInfo" @click="getLoginCode">
        点击登录
      </button>
    </div>
    <div class="card">
      <div class="panel-top" @click="$router.push('/favlist')">
        <div class="left">
          <img src="@/assets/images/icon-zan.svg" class="icon-zan" />
          我的点赞记录
        </div>
        <img src="@/assets/images/arrow.svg" class="arrow" />
      </div>
      <div class="panel-body" v-if="userInfo">
        <div class="item">
          {{ userInfo.today_vote_numbers }}
          <text>今日已点赞</text>
        </div>
        <div class="item">
          {{ userInfo.remain_vote_numbers }}
          <text>今日剩余点赞</text>
        </div>
        <div class="item">
          {{ userInfo.vote_numbers }}
          <text>总点赞</text>
        </div>
      </div>
    </div>
    <div class="card tt">
      <div class="panel-top" @click="testUp">
        <div class="left">
          <img src="@/assets/images/icon-zan.svg" class="icon-zan" />
          test upload
        </div>
        <img src="@/assets/images/arrow.svg" class="arrow" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { userDetail, wechatLogin } from "../network/API";
import { useUserStore } from "../store/user";

const userStore = useUserStore();
const userInfo = ref(null);

onMounted(() => {
  if (userStore.token && userStore.token.length > 0) {
    userInfo.value = userStore.userInfo;
    getUserDetail();
  } else {
    const curPath = window.location.href;
    const code = parseURL(curPath);
    if (code != null && code.length > 0) {
      toLogin(code);
      return;
    }
  }
});
function toClearData() {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
}
async function getUserDetail() {
  const data = await userDetail();
  userInfo.value = data;
  userStore.saveUser(data);
}
function toFavList() {}
async function getLoginCode() {
  const tk = "w04gcoggkowkkosccsgswsgsg0ccosw0sc0k8wwo";
  await userStore.saveToken(tk);
  getUserDetail();
  return;
  window.location.href =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7da5e703d24fa74f&redirect_uri=https://yztzy.smartzhengwu.cn/my&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
}
function parseURL(url) {
  var url = new URL(url);
  var searchParams = new URLSearchParams(url.search);
  var code = searchParams.get("code");
  return code;
}
async function toLogin(code) {
  try {
    var res = await wechatLogin({
      code: code,
    });
    if (res.token) {
      userStore.saveToken(res.token);
    }
    alert("4===" + userStore.token);
    getUserDetail();
  } catch (error) {
    alert("5===" + error);
  }
}
</script>

<style scoped>
.card {
  position: relative;
  z-index: 3;
  margin: 15px;
  padding: 15px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  box-shadow: 0px 15px 15px 0px rgba(69, 42, 124, 0.15);
}

.info {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 190px;
  color: #fff;
}

.info text {
  font-size: 18px;
  font-weight: 700;
}

.headshot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-bottom: 20rpx;
  border: 2rpx solid #fff;
}

.btn-login {
  background: #fff;
  border-radius: 50rpx;
  color: #b84de6;
}

.icon-zan {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.arrow {
  width: 7px;
  height: 13px;
  margin-right: 10px;
}

.panel-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
}

.panel-top .left {
  display: flex;
  align-items: center;
  color: #333;
}

.panel-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.panel-body .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #333333;
}

.panel-body .item text {
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  margin-top: 5px;
}

.tt {
  opacity: 0;
}
</style>
